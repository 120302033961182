import React from 'react';
import { SingleBlogGenre } from 'views/single-blog-genre';
import { useBlogSoftwareBusiness } from 'views/single-blog-genre/hooks/use-blog-software-business';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import messages from 'constants/translations/blog.json';
import softwareBusinessMessages from 'constants/translations/blog/software-business.json';
import { graphql } from 'gatsby';
import Layout from 'layouts/standard';
import { object } from 'prop-types';

const BlogSoftwareBusinessPage = ({ location, data }) => {
    const blogPosts = data.allContentfulBlogPost.edges;
    const faqData = data.allContentfulFaq.nodes[0];
    const pageData = useBlogSoftwareBusiness(blogPosts, faqData);

    return (
        <Layout
            location={location}
            messages={{ ...messages, ...softwareBusinessMessages }}
        >
            <SingleBlogGenre {...pageData} />
        </Layout>
    );
};

export default BlogSoftwareBusinessPage;

BlogSoftwareBusinessPage.propTypes = {
    location: object.isRequired,
    data: object.isRequired,
};

export const Head = () => <SEO tags={TAGS.BLOG_SOFTWARE_BUSINESS} />;
export const pageQuery = graphql`
    query EnSoftwareBusinessBlogPageQuery {
        allContentfulBlogPost(
            filter: {
                node_locale: { eq: "en-US" }
                metadata: {
                    tags: {
                        elemMatch: {
                            name: {
                                in: [
                                    "software-development-business"
                                    "business-intelligence"
                                    "data-science-ML"
                                    "gen-ai"
                                    "entertainment-streaming"
                                    "cloud-devops"
                                    "software-architecture"
                                    "qa-software-testing"
                                    "other"
                                    "ux-ui-design"
                                    "healthtech"
                                ]
                                ne: "nft-reality"
                            }
                        }
                    }
                }
            }
            sort: { date: DESC }
        ) {
            edges {
                node {
                    ...BlogPostFragment
                }
            }
        }
        allContentfulFaq(filter: { slug: { eq: "blog/business" } }) {
            nodes {
                ...FaqFragment
            }
        }
    }
`;
