import DataAnalysisIdea from 'svgs/blog/software-business/data-analysis-idea.svg';
import MobileConcept from 'svgs/blog/software-business/mobile-concept.svg';
import { formatFaqData } from 'utilities/contentful';

import { HUB_URLS } from '../constants';
import { getBlogPagePosts, resolveBlogPageContent } from '../utilities';

const content = resolveBlogPageContent({
    page: 'software-business',
    paragraphsCounters: { firstRow: 1, secondRow: 1 },
    quoteAuthor: {
        name: 'matt-sadowski',
        position: 'ceo-software-expert',
    },
});

export const useBlogSoftwareBusiness = (blogPosts, faqResult) => {
    const { industryLeadersPosts, posts } = getBlogPagePosts(
        blogPosts,
        'industry-leaders',
    );

    const svgs = {
        firstRowSvg: DataAnalysisIdea,
        secondRowSvg: MobileConcept,
    };

    const faqData = formatFaqData(faqResult);

    return {
        ...svgs,
        ...content,
        industryLeadersPosts,
        posts,
        hub: HUB_URLS.SOFTWARE_BUSINESS,
        faqData,
    };
};
